import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { saveState } from './localStorage'
// import { createClient } from '@supabase/supabase-js'
// import { SessionContextProvider } from '@supabase/auth-helpers-react'
// const supabase=createClient(
//   "https://mjpsbjlhfmfucvouxcwy.supabase.co",
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1qcHNiamxoZm1mdWN2b3V4Y3d5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYyMzIyOTYsImV4cCI6MjAwMTgwODI5Nn0.WJS4kk5zisNuo7AqZtM_CLDMQncZb7poLYfKNDfJ9To"
// )

store.subscribe(() => {
    saveState(store.getState())
})

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <SessionContextProvider supabaseClient={supabase}> */}
            <App />
            {/* </SessionContextProvider> */}
        </Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
