import { createStore } from 'redux'
import { loadState } from './localStorage'

const persistedState = loadState()

const initialState = {
  sidebarShow: true,
  lang: 'en',
  wabaID:'',
  update:false,
  
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'lang':
      return { ...state, ...rest }

      case 'setId':
        console.log("rest",rest.id)
        console.log("state",state.wabaID)
        state.wabaID=rest.id
        console.log("state after",state.wabaID)
        return { ...state, ...rest }

        case 'update':
          rest.update=!state.update
          state.update=rest.update
          console.log("usdkasklsaksdka",rest.update)
          return {...state,...rest}
    default:
      return state
  }
}

const store = createStore(changeState, persistedState)
export default store
