import React, { useEffect, useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

const SimmerUi = ({ pageName }) => {
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        // Simulate an asynchronous data loading process
        const simulateDataLoading = () => {
            const interval = setInterval(() => {
                if (progress < 100) {
                    setProgress((prevProgress) => prevProgress + 10)
                } else {
                    clearInterval(interval)
                }
            }, 500) // Simulating a 0.5-second interval data loading process
        }

        simulateDataLoading()
    }, [])
    return (
        <div>
            <div>
                <LoadingBar color="#000" progress={progress} height={4} />
            </div>
        </div>
    )
}

export default SimmerUi
